.my-project-cards-container {
    display: flex;
    align-items: flex-start;
    justify-items: start;
    justify-content: flex-start;
    flex-direction: column;
}

.my-project-preview-card {
    display: flex;
}

.my-project-preview-card > div {
    display: flex;
    flex-direction: column;
}