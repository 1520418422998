body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  line-height: 1.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.global-container .dreamfinity-link {
  font-family: Telegrafico;
  color: #9d69d9;
  text-decoration: none;
}

.global-container .dreamfinity-link:visited {
  color: #9d69d9;
}