.about-page-container {
    display: flex;
    justify-items: center;
    justify-content: center;
}

.long-text-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    min-width: 30vw;
    max-width: 30vw;
}
.long-text-container p {
    text-indent: 0;
}

@media (max-width: 599px) {
    .long-text-container {
        min-width: 90vw;
    }
}

@media (max-width: 999px) {
    .long-text-container {
        min-width: 70vw;
    }
}