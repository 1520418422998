.slidedown-spoiler {
    min-width: 100%;
    padding-bottom: 5px;
    transition: max-height 0.5s cubic-bezier(0.0, 0.0, 1.0, 1.0);
}

@media (max-width: 400px) {
    .slidedown-spoiler {
        width: 360px;
    }
}

.slidedown-spoiler-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-radius: 10px;
    border: 2px solid #2F333B;
    background-color: #363a42;

    cursor: pointer;
}

.slidedown-spoiler-toggle span {
    padding-top: 5px;
    padding-bottom: 5px;
    user-select: none;
}

.spoiler-chevron {
    padding-left: 5px;
    padding-right: 5px;
    height: 10pt;
    width: auto;
}

.spoiler-chevron {
    transition: 0.5s;
}

.spoiler-chevron.is-active {
    transform: rotate(90deg);
}

.spoiler-content {
    height: 0;
    overflow-y: hidden;
    -webkit-transition: height .5s ease;
}

.spoiler-content.is-active {
    height: 100%;
}
