.experience-page-container {
    display: flex;
    justify-items: center;
    justify-content: center;
}

.bullet-list {
    display: flex;
    justify-items: left;
}

.my-github-stats {
    padding-top: 25px;
    display: flex;
    justify-items: center;
    justify-content: center;
    user-select: none;
}

.emphasoft {
    white-space: nowrap;
    font-weight: bold;
    color: #0e96a7;
}

a {
    text-decoration: none;
}

.tooltip {
    cursor: help;
    text-align: center;
    display: inline-block;
    padding: 0;
    margin-left: 5px;
    font-size: 14pt;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #444444;
}
.tooltip-text-background {
}
.tooltip-text {
    line-height: 1em;
    padding: 10px 10px 10px 10px;
    text-align: left;
    visibility: hidden;
    /*width: 350px;*/
    background-color: rgba(68, 68, 68, 0.8);
    /*filter: blur(8px);*/
    backdrop-filter: blur(5px);
    color: #f0f0f0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}

.color-definition-circle {
    translate: -2px 1px;
    margin-left: 2px;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    z-index: 1;
}

.color-definition {
    font-size: 10pt;
}

.key-skills-container {
    display: flex;
    flex-wrap: wrap;
}

.key-skill {
    margin: 3px;
    cursor: pointer;
    padding: 3px 7px 0 7px;
    color: #444444;
    font-weight: bold;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.key-skill-grade {
    width: 100%;
    height: 6px;
    padding-left: 14px;
    margin-left: -7px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.key-skill:hover {
    transform: scale(1.05);
}