.apps-page {
    display: flex;
    justify-items: center;
    justify-content: center;
}

.apps-page-container {
    display: flex;
    justify-items: center;
    align-items: center;;
    flex-direction: column;
    max-width: 60vw;
}

.apps-container {
    display: flex;
    flex-direction: row;
}

.og-preview-card {
    min-height: 100px;
}

.app-card {
    margin: 10px;
    background-color: #2F333B;
    padding: 10px;
    border-radius: 10px;
    transition-duration: 0.1s;
}

.app-card:hover {
    transform: scale(1.1);
    transition-duration: 0.1s;
}

.app-card-title {
    color: #f0f0f0;
    font-weight: bold;
}
.app-card-description {
    /*line-height: 0.8em;*/
    color: #f0f0f0;
    font-size: 14px;
}