.cards-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.simple-card {
    background-color: #363a42;
    display: flex;
    flex-direction: row;
    flex: 1 1 0px;
    justify-content: left;
    border-radius: 10px;
    color: #0AA78D;
    min-height: 120px;
    max-height: 120px;
    max-width: 400px;
    font-size: 14pt;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 10px;
    transition: transform 0.5s;
    height: 100%;
}
.simple-card:hover {
    transform: scale(1.02);
    transition: transform 0.5s;
}

@media (max-width: 290px) {
    .cards-container img {
        display: none;
    }
    .simple-card img {
        display: none;
    }
}
@media (max-width: 800px) {
    .cards-container {
        flex-direction: column;
    }
    .simple-card {
        max-width: 100%;
        font-size: 12pt;
    }
    .simple-card-navlink {
        padding-bottom: 10px;
    }
}

.simple-card-navlink {
    text-decoration: none;
    display: flex;
    flex: 1;
    color: #33869c;
}

.simple-card-title {
    text-align: left;
    font-weight: bold;
    font-size: 16pt;
    line-height: 0.8em;
    margin-bottom: 10px;
}

.simple-card-description {
    color: #f0f0f0;
    font-size: 12pt;
    margin-top: 5px;
    margin-right: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: row;
}

.simple-card-image {
    display: flex;
    align-items: center;
}
.simple-card-image img {
    height: 80px;
    width: auto;
}
