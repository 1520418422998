@font-face {
  font-family: "Telegrafico";
  src: url('../fonts/telegrafico.ttf') ;
}
@font-face {
  font-family: "Ubuntu";
  src: url('../fonts/Ubuntu/Ubuntu-Regular.ttf') ;
}

.header {
  min-height: 6vh;
  min-width: 100vw;
  display: flex;
  color: #ffffff;
  background-color: #282c34;
  font-size: 10pt;
  font-family: "Ubuntu", serif;
  align-items: center;
}
.header img {
  height: 4.5vh;
  width: auto;
  padding-left: 15px;
}
.header-links {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.header-img-link {
  transition: 0.3s;
  align-self: flex-end;
}
.header-img-link:hover {
  transition: 0.5s;
  transform: translateY(10px);
}
.current-page-header-link {
  transform: translateY(10px);
}

.global-container {
  min-height: 90vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  background-color: #282c34;
  font-family: "Ubuntu", serif;
}
.global-container a {
  color: #5eaeef;
}

.global-container a:visited {
  color: #5eaeef;
}

.footer {
  min-height: 4vh;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  background-color: #282c34;
  font-size: 10pt;
  font-family: "Ubuntu", serif;
  justify-content: center;
  align-items: center;
}
.footer img {
  height: 24px;
  width: auto;
  padding-left: 15px;
}

@media (max-width: 325px) {
  .footer img {
    height: 20px;
    width: auto;
  }
}
